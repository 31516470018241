import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  Link as LinkUI,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactTyped } from "react-typed";
import "./styles.css";
import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import { COLORS } from "../../constants/COLORS";

function Hero() {
  const hoverColor = useColorModeValue(COLORS.GRAY, COLORS.WHITE);

  return (
    <Flex
      justifyContent={{ base: "center", md: "center", lg: "space-between" }}
      alignItems={"center"}
      flexWrap={"wrap-reverse"}
      mt={36}
      width={"100%"}
    >
      <Box
        className="intro-container"
        mt={{ base: 30, md: 30 }}
        mb={{ base: 30, md: 30 }}
        w={{ base: "100%", md: "50%" }}
      >
        <Text fontSize={{ base: "2rem", md: "2.8rem" }}>
          Hi <span className="wave">👋</span>,
        </Text>
        <Text fontSize={{ base: "2rem", md: "2.8rem" }}>My name is</Text>
        <Text fontSize={{ base: "2rem", md: "2.8rem" }} className="name">
          João Silva
        </Text>
        <Box width="fit-content" minHeight="120px">
          <Text fontSize={{ base: "2rem", md: "2rem" }}>
            <ReactTyped
              strings={["Full Stack Developer", "Mobile Developer"]}
              typeSpeed={90}
              backSpeed={50}
              loop
            />
          </Text>
        </Box>
        <Flex mt={{ base: 5, md: 0 }} gap={5}>
          <LinkUI mr={5} href="https://github.com/joaovff" target="_blank">
            <Icon
              as={FaGithub}
              boxSize={6}
              cursor="pointer"
              _hover={{
                color: hoverColor,
              }}
            />
          </LinkUI>
          <LinkUI
            mr={5}
            href="https://www.linkedin.com/in/joao-figueiredo-silva"
            target="_blank"
          >
            <Icon
              as={FaLinkedin}
              boxSize={6}
              cursor="pointer"
              _hover={{
                color: hoverColor,
              }}
            />
          </LinkUI>
          <LinkUI
            mr={5}
            href="https://www.instagram.com/jota_vf"
            target="_blank"
          >
            <Icon
              as={FaInstagram}
              boxSize={6}
              cursor="pointer"
              _hover={{
                color: hoverColor,
              }}
            />
          </LinkUI>
        </Flex>
      </Box>

      <Box className="photo-wrapper">
        <Image
          className="photo"
          borderRadius="50%"
          boxSize={{ base: "200px", md: "300px" }}
          src="/photo.jpeg"
          alt="João Silva"
          objectFit={"cover"}
          objectPosition={"center bottom"}
          aspectRatio={"1:1"}
        />
      </Box>
    </Flex>
  );
}

export default Hero;
