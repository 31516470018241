import { Box, Flex } from "@chakra-ui/react";
import Navbar from "./components/NavBar/Navbar";
import Hero from "./components/Hero/Hero";
import Stacks from "./components/Stacks/Stacks";
import Projects from "./components/Projects/Projects";
import { Contact } from "./components/Contact/Contact";
import SideNavigation from "./components/SideNavigation/SideNavigation";
import Footer from "./components/Footer/Footer";
import { consoleMessage } from "./utils/consoleMessage.d";
import { useEffect } from "react";
import { AnimatedBackground } from "./components/AnimatedBackground/AnimatedBackground";
import { Timeline } from "./components/Timeline/Timeline";
import { TimelineDemo } from "./components/TimelineItem/TimelineItem";

function App() {
  useEffect(() => {
    consoleMessage();
  }, []);

  return (
    <Box position="relative" minH="100vh">
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={0}
        pointerEvents="none"
      >
        <AnimatedBackground />
      </Box>

      <Box position="relative" zIndex={1} padding="0 15%">
        <Navbar />
        <Flex
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={44}
        >
          <Hero />
          <TimelineDemo />
          <Stacks />
          <Projects />
          <Contact />
          <Footer />
          {/* <SideNavigation /> */}
        </Flex>
      </Box>
    </Box>
  );
}

export default App;
