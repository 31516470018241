import { Box, Flex, Image, Text, SimpleGrid } from "@chakra-ui/react";
import "./styles.css";
import { ReactTyped } from "react-typed";

function Stacks() {
  const stacks = [
    { src: "/stack-logos/js.png", alt: "Javascript" },
    { src: "/stack-logos/ts.svg", alt: "Typescript" },
    { src: "/stack-logos/react.webp", alt: "React" },
    { src: "/stack-logos/react-native.svg", alt: "React Native" },
    { src: "/stack-logos/nodejs.svg", alt: "Nodejs" },
    { src: "/stack-logos/mongodb.svg", alt: "MongoDB" },
    { src: "/stack-logos/html.svg", alt: "HTML5" },
    { src: "/stack-logos/css.svg", alt: "CSS3" },
    { src: "/stack-logos/handlebars.svg", alt: "Handlebars" },
    { src: "/stack-logos/swift.svg", alt: "Swift" },
    { src: "/stack-logos/kotlin.svg", alt: "Kotlin" },
    { src: "/stack-logos/photoshop.svg", alt: "Photoshop" },
  ];

  return (
    <Flex id={"stacks"} flexDirection={"column"} alignItems={"center"}>
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ReactTyped
          className="title"
          strings={["My Tech Stack"]}
          typeSpeed={90}
        />
        <Text className="subtitle">Technologies and tools</Text>
      </Flex>

      <Flex
        justify={"center"}
        alignItems={"center"}
        mt={12}
        wrap="wrap"
        width="100%"
      >
        <SimpleGrid columns={{ base: 3, md: 6 }} spacing={6} width="100%">
          {stacks.map((item, index) => (
            <Flex key={index} justify="center" alignItems="center">
              <Image src={item.src} alt={item.alt} w="20" h="20" />
            </Flex>
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
}

export default Stacks;
