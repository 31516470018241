import { Box, Flex, Text } from "@chakra-ui/react";
import data from "../../data/projects.json";
import { Project } from "../../@types/project";
import ProjectCard from "../ProjectCard/ProjectCard";
import { ReactTyped } from "react-typed";

function Projects() {
  const projects: Project[] = data;

  return (
    <Flex id={"projects"} flexDirection={"column"} alignItems={"center"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <ReactTyped strings={["Projects"]} typeSpeed={90} className="title" />
        <Text className="subtitle">What I've built so far</Text>
      </Flex>

      <Flex
        mt={{ base: "1rem", md: "auto" }}
        justifyContent={{ base: "center", md: "center", lg: "space-between" }}
        flexWrap={"wrap"}
        alignItems="flex-start"
        gap={10}
      >
        {projects.map((project: Project) => (
          <ProjectCard key={project.title} project={project} />
        ))}
      </Flex>
    </Flex>
  );
}

export default Projects;
