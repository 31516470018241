import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import {
  Box,
  Button,
  FormControl,
  Input,
  Textarea,
  useToast,
  VStack,
  Heading,
  Flex,
  Icon,
  Text,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import { COLORS } from "../../constants/COLORS";
import "./styles.css";
import { SiMinutemailer } from "react-icons/si";
import { ReactTyped } from "react-typed";

export const Contact = () => {
  const form = useRef<HTMLFormElement>(null);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const hoverColor = useColorModeValue(COLORS.GRAY, COLORS.WHITE);

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      setLoading(true);
      setIsAnimating(true);

      try {
        await emailjs.sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
          form.current,
          process.env.REACT_APP_EMAILJS_USER_ID as string
        );
        toast({
          title: "Message sent successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        form.current.reset();
      } catch (error) {
        console.error("Error sending the message:", error);
        toast({
          title: "Error sending the message.",
          description: "Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
        setTimeout(() => {
          setIsAnimating(false);
        }, 1000);
      }
    }
  };

  return (
    <Flex
      flexWrap={"wrap"}
      justifyContent={"space-between"}
      fontFamily={"Poppins"}
      id={"contact"}
      ml={{ base: -6, md: 0 }}
      flexDirection={{ base: "column", md: "row" }}
    >
      <Box flex="2" borderRadius="md" mr={8}>
        <Heading as="h2" size="lg" mb={6} fontFamily={"Poppins"}>
          <ReactTyped strings={["Get in Touch"]} typeSpeed={90} />
        </Heading>
        <Text mb={2}>
          <b className="contact-info">Email:</b> contact@joao-silva.pt
        </Text>
        <Text mb={2}>
          <b className="contact-info">Phone:</b>
          <a href="tel:+351925492565"> +351 925 492 565</a>
        </Text>
        <Text mb={6}>
          <b className="contact-info">Location:</b> Lisbon, Portugal
        </Text>

        <Flex mt={4} mb={4}>
          <Link mr={5} href="https://github.com/joaovff" target="_blank">
            <Icon
              as={FaGithub}
              boxSize={6}
              cursor="pointer"
              _hover={{
                color: hoverColor,
              }}
            />
          </Link>
          <Link
            mr={5}
            href="https://www.linkedin.com/in/joao-figueiredo-silva"
            target="_blank"
          >
            <Icon
              as={FaLinkedin}
              boxSize={6}
              cursor="pointer"
              _hover={{
                color: hoverColor,
              }}
            />
          </Link>
          <Link mr={5} href="https://www.instagram.com/jota_vf" target="_blank">
            <Icon
              as={FaInstagram}
              boxSize={6}
              cursor="pointer"
              _hover={{
                color: hoverColor,
              }}
            />
          </Link>
        </Flex>
      </Box>

      <Box flex="1.5" borderRadius="md" mb={5}>
        <form ref={form} onSubmit={sendEmail}>
          <VStack spacing={1} align="stretch">
            <FormControl mb={4}>
              <Input
                type="text"
                name="user_name"
                placeholder="Your name"
                required
                focusBorderColor={useColorModeValue(
                  COLORS.DARK_BLUE,
                  COLORS.WHITE_SMOKE
                )}
                className="input"
                variant="outline"
              />
            </FormControl>
            <FormControl mb={4}>
              <Input
                type="email"
                name="user_email"
                placeholder="Your email"
                required
                focusBorderColor={useColorModeValue(
                  COLORS.DARK_BLUE,
                  COLORS.WHITE_SMOKE
                )}
                variant="outline"
                className="input"
              />
            </FormControl>
            <FormControl mb={4}>
              <Textarea
                name="message"
                placeholder="Write your message here"
                required
                focusBorderColor={useColorModeValue(
                  COLORS.DARK_BLUE,
                  COLORS.WHITE_SMOKE
                )}
                variant="outline"
                className="input"
              />
            </FormControl>
            <Button
              type="submit"
              isLoading={loading}
              className="input"
              loadingText="Sending..."
              size="lg"
              mt={4}
              variant={"outline"}
              color={useColorModeValue(COLORS.DARK_BLUE, COLORS.WHITE_SMOKE)}
              borderColor={useColorModeValue("transparent", COLORS.GRAY)}
              backgroundColor="transparent"
              _hover={{
                background: useColorModeValue(
                  COLORS.DARK_BLUE,
                  COLORS.WHITE_SMOKE
                ),
                color: useColorModeValue(COLORS.WHITE_SMOKE, COLORS.DARK_BLUE),
              }}
            >
              <SiMinutemailer
                className={isAnimating ? "send-animation" : ""}
                style={{ marginRight: 15 }}
              />
              Send
            </Button>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
};
