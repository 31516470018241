import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { COLORS } from "../constants/COLORS";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === "dark" ? COLORS.DARK : COLORS.WHITE,
        color:
          props.colorMode === "dark" ? COLORS.WHITE_SMOKE : COLORS.DARK_BLUE,
      },
    }),
  },
});

export default theme;
