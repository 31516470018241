import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { COLORS } from "../../constants/COLORS";
const year = new Date().getFullYear();

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue(COLORS.WHITE, COLORS.DARK)}
      color={useColorModeValue(COLORS.DARK, COLORS.WHITE_SMOKE)}
      mt={"-5%"}
    >
      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue(COLORS.DARK_BLUE, COLORS.GRAY)}
      >
        <Container
          as={Stack}
          maxW={"100%"}
          py={4}
          px={0}
          direction={{ base: "column", md: "row" }}
          justify={{ md: "space-between" }}
          align={{ md: "center" }}
        >
          <Text fontFamily={"Poppins, Arial"} fontSize={"sm"}>
            © {year}. All rights reserved
          </Text>
          <Stack direction={"row"} spacing={6}>
            <Text fontSize={"sm"} fontFamily={"Poppins, Arial"}>
              Designed and built by{" "}
              <span className="name" style={{ fontWeight: 500 }}>
                João Silva
              </span>
            </Text>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
