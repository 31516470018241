import React, { useState } from "react";
import {
  Image,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  List,
  ListItem,
  Button,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { Timeline } from "../Timeline/Timeline";
import { COLORS } from "../../constants/COLORS";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

export function TimelineDemo() {
  const textColor = useColorModeValue(COLORS.DARK_BLUE, COLORS.WHITE_SMOKE);
  const backgroundColor = useColorModeValue(COLORS.WHITE_SMOKE, "transparent");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = (images: string[]) => {
    setSelectedImages(images);
    setCurrentImageIndex(0);
    onOpen();
  };

  const handleNextImage = () => {
    if (currentImageIndex < selectedImages.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const data = [
    {
      title: "2024",
      content: (
        <div>
          <Text mb={4} color={textColor}>
            I began an in-depth mobile development course, focusing on React
            Native, Kotlin, Swift, and DevOps. I also attended a UX/UI workshop
            to refine my design skills. In November, I'll be at the Web Summit
            in Lisbon, eager to explore the latest industry trends.
          </Text>
          <List spacing={3}>
            <ListItem>
              <Button
                style={{
                  whiteSpace: "normal",
                  textAlign: "left",
                }}
                variant="link"
                onClick={() =>
                  handleImageClick([
                    "/certificates/crossplatform-1.jpg",
                    "/certificates/crossplatform-2.jpg",
                  ])
                }
              >
                Mobile Development Certificate
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant="link"
                onClick={() =>
                  handleImageClick([
                    "/certificates/uxui-1.jpg",
                    "/certificates/uxui-2.jpg",
                  ])
                }
                style={{
                  whiteSpace: "normal",
                  textAlign: "left",
                }}
              >
                UX/UI Workshop Certificate
              </Button>
            </ListItem>
          </List>
        </div>
      ),
    },
    {
      title: "2023",
      content: (
        <div>
          <Text mb={4} color={textColor}>
            I completed the Full Stack Web Development course at Ironhack and
            embraced a new challenge as a Front-End Developer at Manutan. My
            responsibilities range from creating new features, components, and
            landing pages, to conducting functional tests and identifying and
            resolving bugs.
          </Text>
          <List spacing={3}>
            <ListItem>
              <Button
                style={{
                  whiteSpace: "normal",
                  textAlign: "left",
                }}
                variant="link"
                onClick={() => handleImageClick(["/certificates/ironhack.jpg"])}
              >
                Full Stack Web Development Certificate
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant="link"
                onClick={() =>
                  handleImageClick(["/certificates/typescript.jpg"])
                }
                style={{
                  whiteSpace: "normal",
                  textAlign: "left",
                }}
              >
                Advanced Typescript Certificate
              </Button>
            </ListItem>
          </List>
        </div>
      ),
    },
    {
      title: "2022",
      content: (
        <div>
          <Text mb={4} color={textColor}>
            I worked as a Tachograph Analyst, managing truck drivers' driving
            time and ensuring compliance with legal rest regulations. I analyzed
            data to generate reports that supported decision-making, developing
            a keen eye for detail and a solid understanding of effective time
            management. During this time, I also embarked on a Full Stack Web
            Development course.
          </Text>
          {/*  <List spacing={3}>
            <ListItem>
              <Button
                variant="link"
                onClick={() => handleImageClick(["#"])}
              ></Button>
            </ListItem>
          </List> */}
        </div>
      ),
    },
    {
      title: "...",
      content: (
        <div>
          <></>
        </div>
      ),
    },
    {
      title: "2013",
      content: (
        <div>
          <Text mb={4} color={textColor}>
            At 15 years old, I balanced my school studies with a course in
            Visual Basic and Access 2010, gaining my first taste of programming
            and database management. This early exposure to technology sparked
            my interest in the field, laying the foundation for my future path
            in software development.
          </Text>
          <List spacing={3}>
            <ListItem>
              <Button
                style={{
                  whiteSpace: "normal",
                  textAlign: "left",
                }}
                variant="link"
                onClick={() => handleImageClick(["/certificates/vb.jpeg"])}
              >
                Visual Basic, Access 2010, and Programming Logic Certificate
              </Button>
            </ListItem>
          </List>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full">
      <Timeline data={data} />
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" allowPinchZoom={true}>
        <ModalOverlay />
        <ModalContent
          backgroundColor={"transparent"}
          backdropFilter={"blur(2px)"}
        >
          <ModalCloseButton />
          <ModalBody>
            {selectedImages.length > 0 && (
              <Flex direction="column" alignItems="center" mt={10}>
                <Image
                  src={selectedImages[currentImageIndex]}
                  alt={`Certificate Page ${currentImageIndex + 1}`}
                  width="100%"
                  height="auto"
                  className="rounded-lg"
                />
                {selectedImages.length > 1 && (
                  <Flex mt={4} justifyContent="space-between" width="100%">
                    <IconButton
                      aria-label="Previous"
                      icon={<ArrowBackIcon />}
                      onClick={handlePrevImage}
                      isDisabled={currentImageIndex === 0}
                      color={textColor}
                      backgroundColor={backgroundColor}
                    />
                    <IconButton
                      aria-label="Next"
                      icon={<ArrowForwardIcon />}
                      onClick={handleNextImage}
                      isDisabled={
                        currentImageIndex === selectedImages.length - 1
                      }
                      backgroundColor={backgroundColor}
                      color={textColor}
                    />
                  </Flex>
                )}
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
