import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Image,
  Stack,
  Text,
  IconButton,
  Flex,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { Project } from "../../@types/project";
import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { IoLogoGithub } from "react-icons/io";
import { PiLink } from "react-icons/pi";
import { COLORS } from "../../constants/COLORS";
import "./styles.css";
interface ProjectProps {
  project: Project;
}
function ProjectCard({ project }: ProjectProps) {
  const [expandedTitle, setExpandedTitle] = useState<string | null>(null);
  const handleExpandClick = (title: string) => {
    setExpandedTitle(expandedTitle === title ? null : title);
  };
  return (
    <Card
      mt={8}
      w={80}
      borderRadius="2xl"
      className="card"
      overflow="hidden"
      bg={"transparent"}
      transition="all 0.3s ease-in-out"
      backdropFilter={"blur(1px)"}
    >
      <CardHeader p={0}>
        <Image
          src={project.image}
          alt="Project thumbnail"
          w={"100%"}
          h={"250px"}
          objectFit="cover"
        />
      </CardHeader>
      <CardBody
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack mt="1" spacing="3" flex="1" width="100%">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Heading size="md" className="card-title">
              {project.title}
            </Heading>
            <IconButton
              aria-label={
                expandedTitle === project.title ? "Show less" : "Show more"
              }
              icon={
                expandedTitle === project.title ? (
                  <ChevronUpIcon />
                ) : (
                  <ChevronDownIcon />
                )
              }
              onClick={() => handleExpandClick(project.title)}
              variant="link"
              colorScheme={useColorModeValue(COLORS.DARK_BLUE, COLORS.DARK)}
              size="md"
            />
          </Flex>
          <Text
            noOfLines={expandedTitle === project.title ? undefined : 4}
            width="100%"
            className="card-subtitle"
          >
            {project.description}
          </Text>
        </Stack>
        <Stack>
          <Text
            noOfLines={expandedTitle === project.title ? undefined : 2}
            className="stacks-names"
          >
            Tech Stack: {project.stacks}
          </Text>
        </Stack>
      </CardBody>
      <CardFooter
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {project.url && (
          <Link
            variant="solid"
            colorScheme="blue"
            display={"flex"}
            alignItems={"center"}
            textDecoration={"underline"}
            href={project.url}
            isExternal
          >
            <PiLink size={16} />
            <Text ml={2}>Live Preview</Text>
          </Link>
        )}
        <Link
          variant="ghost"
          colorScheme="blue"
          display={"flex"}
          alignItems={"center"}
          textDecoration={"underline"}
          href={project.githubUrl}
          isExternal
        >
          <IoLogoGithub size={20} />
          <Text ml={2}>View Code</Text>
        </Link>
      </CardFooter>
    </Card>
  );
}
export default ProjectCard;
