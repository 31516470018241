import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Avatar,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import { Link } from "react-scroll";
import { useEffect, useState } from "react";
import "./styles.css";

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
}

const Links = [
  { name: "Home", href: "home" },
  { name: "Journey", href: "journey" },
  { name: "Stacks", href: "stacks" },
  { name: "Projects", href: "projects" },
  { name: "Contact", href: "contact" },
];

const NavLink = ({ href, children }: NavLinkProps) => {
  return (
    <Link
      to={href}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      style={{ textDecoration: "none", cursor: "pointer" }}
    >
      <Box
        px={2}
        py={1}
        rounded={"md"}
        _hover={{
          textDecoration: "none",
        }}
        as="a"
      >
        {children}
      </Box>
    </Link>
  );
};

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const [activeSection, setActiveSection] = useState<string>("home");

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    Links.forEach((link) => {
      const sectionElement = document.getElementById(link.href);
      if (sectionElement) {
        const { top, bottom } = sectionElement.getBoundingClientRect();
        const margin = 250;

        if (top <= window.innerHeight - margin && bottom >= 0) {
          setActiveSection(link.href);
        }
      }
    });

    if (scrollPosition === 0) {
      setActiveSection("home");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box id={"home"} bg="transparent">
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          className={`icon ${isOpen ? "close" : "rotate"}`}
          icon={isOpen ? <CloseIcon boxSize={3} /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
          mr={3}
          backgroundColor={"transparent"}
          _active={{ background: "transparent" }}
          _focus={{ outline: "none" }}
        />
        <Flex
          alignItems={"center"}
          flexGrow={1}
          justifyContent={"space-between"}
        >
          <Box>
            <Avatar src="/logo.png" w={12} h={12} />
          </Box>
          <HStack
            as={"nav"}
            spacing={8}
            display={{ base: "none", md: "flex" }}
            mx="auto"
            fontFamily={"DM Sans"}
          >
            {Links.map((link) => (
              <NavLink key={link.name} href={link.href}>
                <Box>{link.name}</Box>
              </NavLink>
            ))}
          </HStack>
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              ></MenuButton>
              <Button
                onClick={toggleColorMode}
                className="icon"
                backgroundColor={"transparent"}
              >
                {colorMode === "light" ? (
                  <MoonIcon _active={{ background: "transparent" }} />
                ) : (
                  <SunIcon _active={{ background: "transparent" }} />
                )}
              </Button>
            </Menu>
          </Flex>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link.name} href={link.href}>
                {link.name.charAt(0).toUpperCase() + link.name.slice(1)}
              </NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
